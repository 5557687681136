import React, { Suspense } from "react";
const OrderWrapper = React.lazy(() => import("../components/OrderWrapper"));

const OrderPage = () => {
  return (
    <Suspense fallback={<></>}>
      <OrderWrapper />
    </Suspense>
  );
};

export default OrderPage;
