import { authRoute } from '../modules/auth';
import { voucherRoute } from '../modules/voucher';
import { exploreRoute } from '../modules/explore';
import { educationOpenRoute } from '../modules/educationOpen';
import { initiativeRoute } from '../modules/initiative';
import { architectureRoute } from '../modules/architecture';
import { accountRoute } from '../modules/account';
import { settingRoute } from '../modules/setting';
import { developmentRoadmapRoute } from '../modules/developmentRoadmap';
import { portfolioRoute } from '../modules/portfolio';
import { productRoute } from '../modules/product';
import { eventRoute } from '../modules/event';
import { serviceRoute } from '../modules/service';
import { registerServiceRoute } from '../modules/registerService';
import { registerProductRoute } from '../modules/registerProduct';
import { navigationRoute } from '../modules/navigation';
import { orderRoute } from '../modules/order';
import { ComingSoon } from '../modules/base/components/ComingSoon';
import { registerChallengeRoute } from '../modules/registerChallenge';
import { categories } from '../modules/categories';
import { tagRouter } from '../modules/tag';

export const routes = [
    ...authRoute,
    ...voucherRoute,
    ...exploreRoute,
    ...educationOpenRoute,
    ...initiativeRoute,
    ...architectureRoute,
    ...accountRoute,
    ...settingRoute,
    ...settingRoute,
    ...developmentRoadmapRoute,
    ...portfolioRoute,
    ...productRoute,
    ...accountRoute,
    ...eventRoute,
    ...serviceRoute,
    ...registerServiceRoute,
    ...registerChallengeRoute,
    ...registerProductRoute,
    ...navigationRoute,
    ...orderRoute,
    ...categories,
    ...tagRouter,
    {
        path: '*',
        component: ComingSoon,
        unauthenticated: true,
    },
];
