import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  category: {
    status: "",
    data: [],
  },
  tag: {
    status: "",
    data: [],
  },
};

ReducerRegistry.register("common", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CATEGORIES: {
      return {
        ...state,
        category: {
          ...state.category,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.GET_TAGS: {
      return {
        ...state,
        tag: {
          ...state.tag,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
