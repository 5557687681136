import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import Modal from "../../common/components/Modal";

const IconViewModal = ({ isDisabled = false, className = "", modal }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <span
        className={`wrap-icon-action ${
          isDisabled ? "disabled" : ""
        } ${className}`}
        onClick={() => setOpen(true)}
      >
        <EyeIcon className="w-4 h-4" />
        <Modal open={open} setOpen={setOpen}>
          {modal}
        </Modal>
      </span>
    </>
  );
};

export default IconViewModal;
