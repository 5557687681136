import ServicePage from "./pages/ServicePage";
import EditServicePage from "./pages/EditServicePage";
import ServiceChildrenPage from "./pages/ServiceChildrenPage";
import ServiceChildrenEditPage from "./pages/ServiceChildrenEditPage";
export const serviceRoute = [
  {
    path: "/service",
    component: ServicePage,
  },
  {
    path: "/service/create",
    component: EditServicePage,
  },
  {
    path: "/service/:id",
    component: EditServicePage,
  },
  {
    path: "/service/:service_id/child/:id",
    component: ServiceChildrenEditPage,
  },
  {
    path: "/service/:service_id/child/create",
    component: ServiceChildrenEditPage,
  },
  {
    path: "/service/:service_id/child",
    component: ServiceChildrenPage,
  },
];
