import CategoriesPage from "./pages/CategoriesPage";
import CategoriesEditPage from "./pages/CategoriesEditPage";

export const categories = [
  {
    path: "/categories",
    component: CategoriesPage,
  },
  {
    path: "/categories/:id",
    component: CategoriesEditPage,
  },
];
