import React from "react";
import ImageUploading from "react-images-uploading";
import toastr from "toastr";
import { CloudUploadIcon } from "@heroicons/react/outline";

class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      images: [],
      firstValue: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (!this.state.firstValue && value && typeof value === "string") {
      this.setState({
        value: value,
        firstValue: true,
      });
    }
  }

  componentWillReceiveProps(next_props) {
    const { value } = next_props;
    if (!this.state.firstValue && value && typeof value === "string") {
      this.setState({
        value: value,
        firstValue: true,
      });
    } else if (this.props.value !== value) {
        this.setState({
          currentImage: value,
          firstValue: true,
        });
      }
  }

  handleChange = (imageList) => {
    const newFile = this.dataURLtoFile(
      imageList[0].data_url,
      new Date().getTime()
    );
    this.setState({
      value: imageList[0].data_url,
    });
    this.props.onChange(this.props.name, newFile);
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    const extension = dataurl.split(";")[0].split("/")[1];

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + "." + extension, { type: mime });
  };

  onError = (errors, files) => {
    if (errors.acceptType) {
      toastr.error("File up lên không đúng định dạng");
    } else if (errors.maxFileSize) {
      toastr.error("File up lên không được quá 5MB");
    }
  };

  onLoadEnd = (e) => {};

  render() {
    const { label, acceptType } = this.props;
    const { value, images } = this.state;
    return (
      <div className="upload-image">
        <div className="mb-1 font-medium">{label}</div>
        <div className="mt-2">
          <ImageUploading
            onLoadEnd={this.onLoadEnd}
            beforeUpload={(file) => {}}
            onError={this.onError}
            acceptType={acceptType}
            value={images}
            onChange={this.handleChange.bind(this)}
            maxFileSize={5242880}
            dataURLKey="data_url"
          >
            {({ onImageUpload, dragProps }) => (
              <div className="flex items-center">
                <span className="mr-8">
                  <button
                    type="button"
                    onClick={onImageUpload}
                    {...dragProps}
                    className=""
                  >
                    <div className="flex items-center ">
                      <CloudUploadIcon className="w-6 h-6 inline-block mr-4" />
                      <span className="font-medium">Tải lên</span>
                    </div>
                  </button>
                </span>
                <span className="h-40 w-40 overflow-hidden">
                  {value ? (
                    <img className="image-upload" src={value} alt="image" />
                  ) : (
                    <span className="image-upload-default">&nbsp;</span>
                  )}
                </span>
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
    );
  }
}
export default UploadImage;
