import DevelopmentRoadmapPage from "./pages/DevelopmentRoadmapPage";
import DevelopmentRoadmapEditPage from "./pages/DevelopmentRoadmapEditPage";
import ChildDevelopmentRoadmapPage from "./pages/ChildDevelopmentRoadmapPage";
import ChildDevelopmentRoadmaEditPage from "./pages/ChildDevelopmentRoadmaEditPage";

export const developmentRoadmapRoute = [
  {
    path: "/sdgs/:id",
    component: DevelopmentRoadmapEditPage,
  },
  {
    path: "/sdgs/create",
    component: DevelopmentRoadmapEditPage,
  },
  {
    path: "/sdgs",
    component: DevelopmentRoadmapPage,
  },

  // Child router
  {
    path: "/sdgs/:parent_id/child",
    component: ChildDevelopmentRoadmapPage,
  },
  {
    path: "/sdgs/:parent_id/child/create",
    component: ChildDevelopmentRoadmaEditPage,
  },
  {
    path: "/sdgs/:parent_id/child/:id",
    component: ChildDevelopmentRoadmaEditPage,
  },
];
