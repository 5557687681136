import React, { Suspense } from "react";
const AccountWrapper = React.lazy(() => import("../components/AccountWrapper"));

const AccountPage = () => {
  return (
    <Suspense fallback={<></>}>
      <AccountWrapper />
    </Suspense>
  );
};

export default AccountPage;
