import ArchitecturePage from "./pages/ArchitecturePage";
import ArchitectureEditPage from "./pages/ArchitectureEditPage";

export const architectureRoute = [
  {
    path: "/architecture/:id",
    component: ArchitectureEditPage,
  },
  {
    path: "/architecture/create",
    component: ArchitectureEditPage,
  },
  {
    path: "/architecture",
    component: ArchitecturePage,
  },
];
