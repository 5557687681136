import React, { Suspense } from "react";
const ModelProductWrapperEdit = React.lazy(() =>
  import("../components/ModelProductWrapperEdit")
);

const ModelProductEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ModelProductWrapperEdit />
    </Suspense>
  );
};

export default ModelProductEditPage;
