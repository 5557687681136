import React, { Suspense } from "react";
const ChildExploreEditPageWrapper = React.lazy(() =>
  import("../components/ChildExploreEditPageWrapper")
);

const ChildArchiectureEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ChildExploreEditPageWrapper />
    </Suspense>
  );
};

export default ChildArchiectureEditPage;
