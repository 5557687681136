import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import React from "react";

const InputManyRows = ({
  label,
  name,
  value = [],
  onChange,
  placeholder = "",
  type = "text",
  required = false,
  isDisabled = false,
  ...props
}) => {
  const onChangeInput = (newValue, index) => {
    if (value.length > 0) {
      value[index] = type === 'number' ? parseInt(newValue) : newValue;
    } else {
      value[0] = type === 'number' ? parseInt(newValue) : newValue;
    }
    onChange(name, value);
  };

  const onAddRow = () => {
    if (value.length > 0) {
      value.push(null);
    } else {
      value = [null, null];
    }
    onChange(name, value);
  };

  const onDeleteRow = (k) => {
    value.splice(k, 1);
    onChange(value);
  };

  const renderItem = (item, k) => {
    return (
      <div className="flex items-center  mt-1" key={k}>
        <div style={{ width: "calc(100% - 40px)" }}>
          <input
            className={`block w-full bg-white py-2 pl-2 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:placeholder-gray-500 sm:text-sm ${
              isDisabled ? "bg-gray-100 cursor-not-allowed" : ""
            }`}
            placeholder={placeholder}
            type={type}
            name={name}
            value={item}
            onChange={(e) => onChangeInput(e.target.value, k)}
            required={required}
            disabled={isDisabled}
            {...props}
          />
        </div>
        <div className="text-right" style={{ width: 40 }}>
          <TrashIcon
            className="inline-block w-6 h-6 text-red-600 cursor-pointer"
            onClick={() => onDeleteRow(k)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mt-2">
      <div className="mb-1 font-medium">{label}</div>
      <div className="w-full">
        {value.length > 0
          ? value.map((item, k) => renderItem(item, k))
          : renderItem(value[0] || null, 0)}
      </div>
      <div className="mt-2">
        <div className="flex items-center cursor-pointer" onClick={onAddRow}>
          <PlusIcon className="w-4 h-4 ml-2" /> Thêm dòng
        </div>
      </div>
    </div>
  );
};

export default React.memo(InputManyRows);
