import React, { useState } from "react";
import { useEffect } from "react";
import toastr from "toastr";
const InputUpload = ({
  label,
  name,
  value,
  onChange,
  placeholder = "",
  type = "text",
  required = false,
  accept = "image/*",
  extensions = ["jpg", "jpeg", "png", "svg"],
  maxFileSize = 5,
  ...props
}) => {
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    if (typeof value !== "string") {
      setImagePreviews(value);
    }
  }, [value]);

  const onChangeInput = (e) => {
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }
    onChange(name, e.target.files);
    setImagePreviews(images);

    const file = e.target.files[0];
    if (file) {
      const temp = file.name.split(".");
      if (extensions && !extensions.includes(temp.slice(-1)[0])) {
        return toastr.error("File upload không đúng định dạng");
      }
      if (file.size >= maxFileSize * 1024 * 1024) {
        return toastr.error(`File upload tối đa ${maxFileSize}MB`);
      }
      // onChange(name, file);
    }
  };

  return (
    <div className="mt-2">
      <div className="mb-1 font-bold">{label}</div>
      <input
        type="file"
        className="bg-white py-2"
        placeholder={placeholder}
        name={name}
        onChange={onChangeInput}
        required={required}
        accept={accept}
        {...props}
        multiple
      />
      {
        <div className="mt-2 flex space-x-2">
          {Array.isArray(imagePreviews) &&
            imagePreviews?.map((img) => {
              return (
                <div>
                  <img
                    className="h-40 w-40"
                    src={img?.size ? URL.createObjectURL(img) : img}
                  />
                </div>
              );
            })}
        </div>
      }
    </div>
  );
};

export default React.memo(InputUpload);
