import React, { Suspense } from "react";
const VoucherWrapper = React.lazy(() => import("../components/VoucherWrapper"));

const VoucherPage = () => {
  return (
    <Suspense fallback={<></>}>
      <VoucherWrapper />
    </Suspense>
  );
};

export default VoucherPage;