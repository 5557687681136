import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  developmentRoadmaps: {
    status: "",
    data: [],
    total: 0,
  },
};

ReducerRegistry.register("developmentRoadmap", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_DEVELOPMENT_ROADMAPS: {
      return {
        ...state,
        developmentRoadmaps: {
          ...state.developmentRoadmaps,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
