import RegisterProductPage from './pages/RegisterProductPage';
export const registerProductRoute = [
    {
        path: '/register-product-customer',
        component: RegisterProductPage,
    },
    {
        path: '/register-product-expert',
        component: RegisterProductPage,
    },
];
