import React, { Suspense } from "react";
const CategoriesPageWrapper = React.lazy(() =>
  import("../components/CategoriesPageWrapper")
);

const RegisterServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <CategoriesPageWrapper />
    </Suspense>
  );
};

export default RegisterServicePage;
