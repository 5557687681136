import React, { Suspense } from "react";
const ServiceChildrenWrapper = React.lazy(() =>
  import("../components/ServiceChildrenWrapper")
);

const ServiceChildrenEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServiceChildrenWrapper />
    </Suspense>
  );
};

export default ServiceChildrenEditPage;
