import PortfolioPage from "./pages/PortfolioPage";
import PortfolioEditPage from "./pages/PortfolioEditPage";
import PortfolioCompanyPage from "./pages/PortfolioCompanyPage";
import PortfolioCompanyEditPage from "./pages/PortfolioCompanyEditPage";

export const portfolioRoute = [
  {
    path: "/portfolio/:portfolio_id/company/:id",
    component: PortfolioCompanyEditPage,
  },
  {
    path: "/portfolio/:portfolio_id/company/create",
    component: PortfolioCompanyEditPage,
  },
  {
    path: "/portfolio/:portfolio_id/company",
    component: PortfolioCompanyPage,
  },
  {
    path: "/portfolio/:id",
    component: PortfolioEditPage,
  },
  {
    path: "/portfolio/create",
    component: PortfolioEditPage,
  },
  {
    path: "/portfolio",
    component: PortfolioPage,
  },
];
