import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  educationOpens: {
    status: "",
    data: [],
    total: 0,
  },
  category: {
    status: "",
    data: [],
  },
};

ReducerRegistry.register("educationOpen", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_EDUCATION_OPENS: {
      return {
        ...state,
        educationOpens: {
          ...state.educationOpens,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
