import ProductPage from "./pages/ProductPage";
import ProductEditPage from "./pages/ProductEditPage";

export const productRoute = [
  {
    path: "/product/:id",
    component: ProductEditPage,
  },
  {
    path: "/product/create",
    component: ProductEditPage,
  },
  {
    path: "/product",
    component: ProductPage,
  },
];
