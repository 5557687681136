export const ACTION_TYPES = {
  SET_AUTH: Symbol("SET_AUTH"),
  CLEAR_CACHES: Symbol("CLEAR_CACHES"),
  GET_WEBSITE_BY_DOMAIN: Symbol("GET_WEBSITE_BY_DOMAIN"),
  GET_NOTIFICATIONS: Symbol("GET_NOTIFICATIONS"),
  UPDATE_CMS_SETTING: Symbol("UPDATE_CMS_SETTING"),
  UPDATE_WEBSITE_INFO: Symbol("UPDATE_WEBSITE_INFO"),
  CHECK_WALLET_NFT: Symbol("CHECK_WALLET_NFT"),
  CREATE_WALLET_NFT: Symbol("CREATE_WALLET_NFT"),
};
