import React, { Suspense } from "react";
const ProductEditWrapper = React.lazy(() =>
  import("../components/ProductEditWrapper")
);

const ProductEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ProductEditWrapper />
    </Suspense>
  );
};

export default ProductEditPage;
