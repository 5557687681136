import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  order: { status: "", data: [], total: 0 },
  paymentMethod: { status: "", data: [], total: 0 },
};

ReducerRegistry.register("order", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ORDER: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case ACTION_TYPES.GET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload,
      };
    }
    default:
      return state;
  }
});
