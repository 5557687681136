import React, { Suspense } from "react";
const NavigationEditWrapper = React.lazy(() =>
  import("../components/NavigationEditWrapper")
);

const NavigationEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <NavigationEditWrapper />
    </Suspense>
  );
};

export default NavigationEditPage;
