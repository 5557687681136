import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { COMPONENT_STATUS } from "../../common";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  auth: null,
  website: null,
  notifications: null,
  statusApi: "",
  statusUpdateWebsiteInfo: "",
  walletNFT: {
    status: "",
    data: null,
  },
  statusCreateWalletNFT: "",
};

ReducerRegistry.register("caches", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_AUTH:
      return {
        ...state,
        auth: action.auth,
      };
    case ACTION_TYPES.GET_WEBSITE_BY_DOMAIN:
      return {
        ...state,
        website: action.payload.data,
        statusApi: action.payload.status,
      };
    case ACTION_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.data,
      };
    case ACTION_TYPES.UPDATE_CMS_SETTING:
      const { name, value } = action.payload;
      return {
        ...state,
        website: {
          ...state.website,
          cms_setting: {
            ...state.website.cms_setting,
            [name]: value,
          },
        },
      };
    case ACTION_TYPES.UPDATE_WEBSITE_INFO:
      if (action.payload.status === COMPONENT_STATUS.SUCCESS) {
        return {
          ...state,
          website: action.payload.data,
          statusUpdateWebsiteInfo: action.payload.status,
        };
      } else {
        return {
          ...state,
          statusUpdateWebsiteInfo: action.payload.status,
        };
      }
    case ACTION_TYPES.CHECK_WALLET_NFT:
      return {
        ...state,
        walletNFT: {
          ...state.walletNFT,
          ...action.payload,
        },
      };
    case ACTION_TYPES.CREATE_WALLET_NFT:
      return {
        ...state,
        statusCreateWalletNFT: action.payload.status,
      };
    case ACTION_TYPES.CLEAR_CACHES:
      return initState;
    default:
      return state;
  }
});
