import React, { Suspense } from 'react';
const TagEditPageWrapper = React.lazy(() => import('../components/TagEditPageWrapper'));

const TagEditPage = () => {
    return (
        <Suspense fallback={<></>}>
            <TagEditPageWrapper />
        </Suspense>
    );
};

export default TagEditPage;
