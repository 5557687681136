import AccountPage from "./pages/AccountPage";
import AccountEditPage from "./pages/AccountEditPage";

export const accountRoute = [
  {
    path: "/account/:id",
    component: AccountEditPage,
  },
  {
    path: "/account/create",
    component: AccountEditPage,
  },
  {
    path: "/account",
    component: AccountPage,
  },
];
