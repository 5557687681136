import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useLocation, Link } from 'react-router-dom';
import UserImage from '../../assets/images/user.png';
import {
    AcademicCapIcon,
    MenuAlt2Icon,
    XIcon,
    UserIcon,
    GlobeIcon,
    ShoppingBagIcon,
    CogIcon,
    NewspaperIcon,
    ArchiveIcon,
    ServerIcon,
    MapIcon,
    BriefcaseIcon,
    RssIcon,
} from '@heroicons/react/outline';

import { LeftMenuItem } from './LeftMenuItem';
import { CubeTransparentIcon, LightBulbIcon } from '@heroicons/react/solid';
const sidebarNavigation = [
    {
        name: 'Explore',
        href: '/explore',
        icon: GlobeIcon,
    },
    {
        name: 'Architecture',
        href: '/architecture',
        icon: ArchiveIcon,
    },
    {
        name: 'Challenge',
        href: '/challenge',
        icon: AcademicCapIcon,
    },
    {
        name: 'Portfolio',
        href: '/portfolio',
        icon: LightBulbIcon,
    },
    {
        name: 'Product',
        href: '/product',
        icon: CubeTransparentIcon,
    },
    {
        name: 'Market',
        href: '/model-product',
        icon: ShoppingBagIcon,
        submenu: [
            {
                name: '3D Model',
                href: '/model-product',
                icon: CogIcon,
            },
            {
                name: 'Order list',
                href: '/orders',
                icon: CogIcon,
            },
            {
                name: 'Payment method',
                href: '/payment-method',
                icon: CogIcon,
            },
        ],
    },
    {
        name: 'Service',
        href: '/service',
        icon: ServerIcon,
    },
    {
        name: 'SDGs',
        href: '/sdgs',
        icon: CogIcon,
    },
    {
        name: 'Account',
        href: '/account',
        icon: UserIcon,
    },
    {
        name: 'Event',
        href: '/event',
        icon: NewspaperIcon,
    },
    {
        name: 'Navigation',
        href: '/navigation',
        icon: MapIcon,
    },
    {
        name: 'Register Service',
        href: '/register-service-customer',
        icon: UserIcon,
        submenu: [
            {
                name: 'Customer',
                href: '/register-service-customer',
                icon: CogIcon,
            },
            {
                name: 'Expert',
                href: '/register-service-expert',
                icon: CogIcon,
            },
        ],
    },
    {
        name: 'Register Challenge',
        href: '/register-challenge-customer',
        icon: BriefcaseIcon,
        submenu: [
            {
                name: 'Customer',
                href: '/register-challenge-customer',
                icon: CogIcon,
            },
            {
                name: 'Expert',
                href: '/register-challenge-expert',
                icon: CogIcon,
            },
        ],
    },
    {
        name: 'Register Product',
        href: '/register-product-customer',
        icon: BriefcaseIcon,
        submenu: [
            {
                name: 'Customer',
                href: '/register-product-customer',
                icon: CogIcon,
            },
            {
                name: 'Expert',
                href: '/register-product-expert',
                icon: CogIcon,
            },
        ],
    },
    {
        name: 'Categories',
        href: '/categories',
        icon: MapIcon,
    },
    {
        name: 'Product Tag',
        href: '/tag/Product',
        icon: RssIcon,
    },
];

export const LeftMenu = () => {
    const currentUrl = useLocation().pathname;
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [sidebarMenu, setSidebarMenu] = useState(sidebarNavigation);
    const handleSubmenu = (item) => {
        if (Array.isArray(item?.submenu)) {
            setSidebarMenu(
                sidebarMenu.map((menu) => {
                    if (menu?.href == item?.href) {
                        return {
                            ...menu,
                            enableSubmenu: true,
                        };
                    }
                    return menu;
                })
            );
        } else {
            setSidebarMenu(sidebarNavigation);
        }
    };

    const checkMenuActive = (submenu) => {
        const checkActive = submenu.find(({ href }) => currentUrl.includes(href));
        return checkActive ? 'active' : '';
    };
    useEffect(() => {}, []);

    return (
        <>
            <div className="left-menu">
                <div className="w-full flex flex-col flex-1 items-center relative overflow-x-hidden">
                    <div className="left-menu-header justify-between group relative">
                        <label className="whitespace-nowrap overflow-hidden text-ellipsis">BDSG Group</label>
                    </div>

                    <div className="main-menu">
                        {sidebarMenu.map((item, k) => (
                            <>
                                <Link
                                    to={item?.href}
                                    className={`left-menu-item ${currentUrl.includes(item?.href) ? 'active' : ''}`}
                                    aria-current={currentUrl.includes(item?.href) ? 'page' : undefined}
                                    onClick={() => handleSubmenu(item)}
                                >
                                    <item.icon className="left-menu-item-icon" aria-hidden="true" />
                                    <span>{item?.name}</span>
                                </Link>
                                {item?.enableSubmenu &&
                                    item?.submenu?.map(({ name, href }, j) => (
                                        <div className={`${currentUrl === href ? 'active' : ''} submenu-item-mobile`}>
                                            <Link to={href}>{name}</Link>
                                        </div>
                                    ))}
                            </>
                        ))}

                        {/* {!isIncludeSubmenu ? (
                            <></>
                        ) : (
                            <>
                                {item.submenu.map(({ name, href }, j) => (
                                    <div className={`${currentUrl === href ? 'active' : ''} submenu-item-mobile`}>
                                        <Link to={href}>{name}</Link>
                                    </div>
                                ))}
                            </>
                        )} */}
                    </div>
                    {/* {sidebarNavigation.map(({ submenu }, key) =>
                        submenu ? (
                            <ul className={`submenu ${checkMenuActive(submenu)}`} key={key}>
                                {submenu.map(({ name, href }, j) => (
                                    <li className="w-full" key={j}>
                                        <Link className={`${currentUrl === href ? 'active' : ''}`} to={href}>
                                            {name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            ''
                        )
                    )} */}
                </div>
                {/* <div className="flex-shrink-0 pb-5">
          <AvatarDropdown />
        </div> */}
            </div>

            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" static className="md:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative max-w-xs w-full left-menu-background pt-5 pb-4 flex-1 flex flex-col">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-1 right-0 -mr-14 p-1">
                                        <button
                                            type="button"
                                            className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            <span className="sr-only">Close sidebar</span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="px-3 py-2 border-b w-full text-gray-300 font-medium flex items-center">
                                    <img className="rounded-full h-8 w-8 mr-1" src={UserImage} alt="" /> Datahub
                                </div>
                                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                                    <nav className="h-full flex flex-col">
                                        <div className="space-y-1">
                                            {sidebarMenu.map((item, k) => (
                                                <>
                                                    <Link
                                                        to={item?.href}
                                                        className={`left-menu-item ${
                                                            currentUrl.includes(item?.href) ? 'active' : ''
                                                        }`}
                                                        aria-current={
                                                            currentUrl.includes(item?.href) ? 'page' : undefined
                                                        }
                                                        onClick={() => handleSubmenu(item)}
                                                    >
                                                        <item.icon className="left-menu-item-icon" aria-hidden="true" />
                                                        <span>{item?.name}</span>
                                                    </Link>
                                                    {item?.enableSubmenu &&
                                                        item?.submenu?.map(({ name, href }, j) => (
                                                            <div
                                                                className={`${
                                                                    currentUrl === href ? 'active' : ''
                                                                } submenu-item-mobile`}
                                                            >
                                                                <Link to={href}>{name}</Link>
                                                            </div>
                                                        ))}
                                                </>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <button
                type="button"
                className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
        </>
    );
};
