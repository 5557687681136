import React from "react";
import CircleLoading from "./CircleLoading";

const ButtonSubmit = ({
  children,
  isFetching,
  size = 20,
  disabled = false,
}) => {
  return (
    <button
      className={`btn-custom ${disabled ? "disabled" : ""}`}
      disabled={isFetching || disabled}
      type="submit"
    >
      {isFetching ? <CircleLoading size={size} /> : children}
    </button>
  );
};
export default React.memo(ButtonSubmit);
