import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setAccessToken } from "./modules/common";
import { setAuth } from "./modules/caches";
import { history, PrivateRoute, AppLayout } from "./modules/base";
import "./App.scss";
import { CommonService } from "./modules/common/redux/services";
import { useDispatch, useSelector } from "react-redux";

function App({ routes }) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.caches);

  useEffect(() => {
    // get params from admin page
    // const params = new URL(document.location).searchParams;
    // const token = params.get("token");
    // if (token) {
    //   setAccessToken(token);
    //   CommonService.apiGetUserInfoByToken()
    //     .then((res) => {
    //       if (res && res.success) {
    //         const { access_token, data } = res;
    //         dispatch(setAuth({ ...data, access_token }));
    //         history.push({
    //           pathname: "/dashboard",
    //           search: "",
    //         });
    //       } else {
    //         history.push({
    //           pathname: "/login",
    //           search: "",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       history.push({
    //         pathname: "/login",
    //         search: "",
    //       });
    //     });
    // } else
    if (auth && auth.access_token) {
      setAccessToken(auth.access_token);
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        {routes
          .filter(({ noAppLayout }) => noAppLayout === true)
          .map((route, k) => {
            if (route.unauthenticated) {
              return (
                <Route
                  exact
                  key={k}
                  path={route.path}
                  component={route.component}
                />
              );
            } else {
              return (
                <PrivateRoute
                  exact
                  key={k}
                  path={route.path}
                  component={route.component}
                  permissions={route.permissions}
                />
              );
            }
          })}
        <AppLayout>
          <Switch>
            {routes
              .filter(({ noAppLayout }) => noAppLayout !== true)
              .map((route, k) => {
                if (route.unauthenticated) {
                  return (
                    <Route
                      key={`private-${k}`}
                      path={route.path}
                      component={route.component}
                    />
                  );
                } else {
                  return (
                    <PrivateRoute
                      exact
                      key={`private-${k}`}
                      path={route.path}
                      component={route.component}
                      permissions={route.permissions}
                    />
                  );
                }
              })}
          </Switch>
        </AppLayout>
      </Switch>
    </Router>
  );
}

export default App;
