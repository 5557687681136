import React, { Suspense } from "react";
const ChildArchitectureEditPageWrapper = React.lazy(() =>
  import("../components/ChildArchitectureEditPageWrapper")
);

const ChildArchitectureEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ChildArchitectureEditPageWrapper />
    </Suspense>
  );
};

export default ChildArchitectureEditPage;
