import React, { Suspense } from "react";
const PortfolioCompanyWrapper = React.lazy(() =>
  import("../components/PortfolioCompanyWrapper")
);

const PortfolioCompanyPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PortfolioCompanyWrapper />
    </Suspense>
  );
};

export default PortfolioCompanyPage;
