import EventPage from "./pages/EventPage";
import EventEditPage from "./pages/EventEditPage";

export const eventRoute = [
  {
    path: "/event/:id",
    component: EventEditPage,
  },
  {
    path: "/event/create",
    component: EventEditPage,
  },
  {
    path: "/event",
    component: EventPage,
  },
];
