import React, { Suspense } from 'react';
const RegisterProductPageWrapper = React.lazy(() => import('../components/RegisterProductPageWrapper'));

const RegisterProductPage = () => {
    return (
        <Suspense fallback={<></>}>
            <RegisterProductPageWrapper />
        </Suspense>
    );
};

export default RegisterProductPage;
