import NavigationPage from "./pages/NavigationPage";
import NavigationEditPage from "./pages/NavigationEditPage";

export const navigationRoute = [
  {
    path: "/navigation/:id",
    component: NavigationEditPage,
  },
  {
    path: "/navigation/create",
    component: NavigationEditPage,
  },
  {
    path: "/navigation",
    component: NavigationPage,
  },
];
