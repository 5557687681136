import React, { Suspense } from "react";
const EventEditWrapper = React.lazy(() =>
  import("../components/EventEditWrapper")
);

const EventEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <EventEditWrapper />
    </Suspense>
  );
};

export default EventEditPage;
