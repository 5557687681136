import React from "react";
import { LeftMenu } from "./left-menu/LeftMenu";

const AppLayout = ({ children }) => {
  return (
    <div className="admin-app">
      <div className="admin-app-left">
        <LeftMenu />
      </div>
      <div className="admin-app-right">{children}</div>
    </div>
  );
};

export default AppLayout;
