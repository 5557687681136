import React, { Suspense } from "react";
const EducationOpenWrapper = React.lazy(() =>
  import("../components/EducationOpenWrapper")
);

const EducationOpenPage = () => {
  return (
    <Suspense fallback={<></>}>
      <EducationOpenWrapper />
    </Suspense>
  );
};

export default EducationOpenPage;
