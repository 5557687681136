import React, { Suspense } from "react";
const CreateServiceWrapper = React.lazy(() =>
  import("../components/EditServiceWrapper")
);

const ServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <CreateServiceWrapper />
    </Suspense>
  );
};

export default ServicePage;
