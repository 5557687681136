import VoucherPage from "./pages/VoucherPage";
import VoucherEditPage from "./pages/VoucherEditPage";

export const voucherRoute = [
  {
    path: "/voucher/:id",
    component: VoucherEditPage,
  },
  {
    path: "/voucher/create",
    component: VoucherEditPage,
  },
  {
    path: "/voucher",
    component: VoucherPage,
  }
];
