import React, { Suspense } from "react";
const AccountEditWrapper = React.lazy(() =>
  import("../components/AccountEditWrapper")
);

const AccountEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <AccountEditWrapper />
    </Suspense>
  );
};

export default AccountEditPage;
