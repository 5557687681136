import React, { Suspense } from "react";
const DevelopmentRoadmapWrapper = React.lazy(() =>
  import("../components/DevelopmentRoadmapWrapper")
);

const DevelopmentRoadmapPage = () => {
  return (
    <Suspense fallback={<></>}>
      <DevelopmentRoadmapWrapper />
    </Suspense>
  );
};

export default DevelopmentRoadmapPage;
