import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  architectures: {
    status: "",
    data: [],
    total: 0,
  },
};

ReducerRegistry.register("architecture", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ARCHITECTURES: {
      return {
        ...state,
        architectures: {
          ...state.architectures,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
