import TagPage from './pages/TagPage';
import TagEditPage from './pages/TagEditPage';

export const tagRouter = [
    {
        path: '/tag/:type',
        component: TagPage,
    },
    {
        path: '/tag/edit/:id',
        component: TagEditPage,
    },
];
