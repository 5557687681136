import React, { Suspense } from "react";
const ChildDevelopmentRoadmaEditPageWrapper = React.lazy(() =>
  import("../components/ChildDevelopmentRoadmaEditPageWrapper")
);

const ChildDevelopmentRoadmaEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ChildDevelopmentRoadmaEditPageWrapper />
    </Suspense>
  );
};

export default ChildDevelopmentRoadmaEditPage;
