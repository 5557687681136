import React, { Suspense } from "react";
const CategoriesEditPageWrapper = React.lazy(() =>
  import("../components/CategoriesEditPageWrapper")
);

const RegisterServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <CategoriesEditPageWrapper />
    </Suspense>
  );
};

export default RegisterServicePage;
