import React, { Suspense } from "react";
const SettingWrapper = React.lazy(() => import("../components/SettingWrapper"));

const SettingPage = () => {
  return (
    <Suspense fallback={<></>}>
      <SettingWrapper />
    </Suspense>
  );
};

export default SettingPage;
