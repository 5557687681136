import React, { Suspense } from "react";
const PaymentMethodWrapper = React.lazy(() =>
  import("../components/PaymentMethodWrapper")
);

const PaymentMethodPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PaymentMethodWrapper />
    </Suspense>
  );
};

export default PaymentMethodPage;
