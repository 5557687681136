import  ReducerRegistry  from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  user: null,
  isFetchingLogin: false,
  error: "",
};

ReducerRegistry.register("auth", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        isFetchingLogin: true,
        error: "",
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        isFetchingLogin: false,
        error: "",
      };
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        isFetchingLogin: false,
        error: action.error,
      };
    default:
      return state;
  }
});
