import EducationOpenPage from "./pages/EducationOpenPage";
import EducationOpenEditPage from "./pages/EducationOpenEditPage";

export const educationOpenRoute = [
  {
    path: "/challenge/:id",
    component: EducationOpenEditPage,
  },
  {
    path: "/challenge/create",
    component: EducationOpenEditPage,
  },
  {
    path: "/challenge",
    component: EducationOpenPage,
  },
];
