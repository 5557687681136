import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  accounts: {
    status: "",
    data: [],
    total: 0,
  },
  userType: {
    status: "",
    data: [],
  },
};

ReducerRegistry.register("account", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ACCOUNTS: {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.GET_USER_TYPES: {
      return {
        ...state,
        userType: {
          ...state.userType,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
