import React, { Suspense } from "react";
const EducationOpenEditWrapper = React.lazy(() =>
  import("../components/EducationOpenEditWrapper")
);

const EducationOpenEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <EducationOpenEditWrapper />
    </Suspense>
  );
};

export default EducationOpenEditPage;
