import React, { Suspense } from "react";
const EventWrapper = React.lazy(() => import("../components/EventWrapper"));

const EventPage = () => {
  return (
    <Suspense fallback={<></>}>
      <EventWrapper />
    </Suspense>
  );
};

export default EventPage;
