import React, { Suspense } from "react";
const ChildExplorePageWrapper = React.lazy(() =>
  import("../components/ChildExplorePageWrapper")
);

const ChildExplorePage = () => {
  return (
    <Suspense fallback={<></>}>
      <ChildExplorePageWrapper />
    </Suspense>
  );
};

export default ChildExplorePage;
