import RegisterServicePage from "./pages/RegisterServicePage";
export const registerServiceRoute = [
  {
    path: "/register-service-customer",
    component: RegisterServicePage,
  },
  {
    path: "/register-service-expert",
    component: RegisterServicePage,
  },
];
