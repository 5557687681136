import React, { Suspense } from "react";
const ArchitectureEditWrapper = React.lazy(() =>
  import("../components/ArchitectureEditWrapper")
);

const ArchitectureEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ArchitectureEditWrapper />
    </Suspense>
  );
};

export default ArchitectureEditPage;
