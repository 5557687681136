import SettingPage from "./pages/SettingPage";
import SettingEditPage from "./pages/SettingEditPage";

export const settingRoute = [
  {
    path: "/setting/:id",
    component: SettingEditPage,
  },
  {
    path: "/setting/create",
    component: SettingEditPage,
  },
  {
    path: "/setting",
    component: SettingPage,
  },
];
