import React, { Suspense } from "react";
const RegisterServicePageWrapper = React.lazy(() =>
  import("../components/RegisterServicePageWrapper")
);

const RegisterServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <RegisterServicePageWrapper />
    </Suspense>
  );
};

export default RegisterServicePage;
