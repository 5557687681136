import React, { Suspense } from "react";
const PortfolioCompanyEditWrapper = React.lazy(() =>
  import("../components/PortfolioCompanyEditWrapper")
);

const PortfolioCompanyEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PortfolioCompanyEditWrapper />
    </Suspense>
  );
};

export default PortfolioCompanyEditPage;
