import React from "react";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/outline";

const IconEdit = ({
  to,
  isDisabled = false,
  className = "",
  onClick = null,
}) => {
  return onClick ? (
    <span
      className={`wrap-icon-action ${
        isDisabled ? "disabled" : ""
      } ${className}`}
      onClick={onClick}
    >
      <CheckCircleIcon className="w-4 h-4" />
    </span>
  ) : (
    <Link
      className={`wrap-icon-action ${
        isDisabled ? "disabled" : ""
      } ${className}`}
      to={to}
    >
      <CheckCircleIcon className="w-4 h-4" />
    </Link>
  );
};

export default IconEdit;
