import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  initiatives: {
    status: "",
    data: [],
    total: 0,
  },
};

ReducerRegistry.register("initiative", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_INITIATIVES: {
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
