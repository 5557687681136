import ExplorePage from "./pages/ExplorePage";
import ExploreEditPage from "./pages/ExploreEditPage";
import ChildExplorePage from "./pages/ChildExplorePage";
import ChildExploreEditPage from "./pages/ChildExploreEditPage";
import ChildArchitecturePage from "./pages/ChildArchitecturePage";
import ChildArchitectureEditPage from "./pages/ChildArchitectureEditPage";

export const exploreRoute = [
  {
    path: "/explore/:id",
    component: ExploreEditPage,
  },
  {
    path: "/explore/create",
    component: ExploreEditPage,
  },
  {
    path: "/explore",
    component: ExplorePage,
  },

  // Child router
  {
    path: "/explore/:parent_id/child",
    component: ChildExplorePage,
  },
  {
    path: "/explore/:parent_id/child/create",
    component: ChildExploreEditPage,
  },
  {
    path: "/explore/:parent_id/child/:id",
    component: ChildExploreEditPage,
  },
  // Child Architecture
  {
    path: "/explore/:parent_id/architecture",
    component: ChildArchitecturePage,
  },
  {
    path: "/explore/:parent_id/architecture/create",
    component: ChildArchitectureEditPage,
  },
  {
    path: "/explore/:parent_id/architecture/:id",
    component: ChildArchitectureEditPage,
  },
];
