import React, { Suspense } from "react";
const ModelProductWrapper = React.lazy(() =>
  import("../components/ModelProductWrapper")
);

const ModelProductPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ModelProductWrapper />
    </Suspense>
  );
};

export default ModelProductPage;
