import InitiativePage from "./pages/InitiativePage";
import InitiativeEditPage from "./pages/InitiativeEditPage";

export const initiativeRoute = [
  {
    path: "/initiative/:id",
    component: InitiativeEditPage,
  },
  {
    path: "/initiative/create",
    component: InitiativeEditPage,
  },
  {
    path: "/initiative",
    component: InitiativePage,
  },
];
