import React, { Suspense } from "react";
const LoginPageWrapper = React.lazy(() =>
  import("../components/LoginPageWrapper")
);
const LoginPage = () => {
  return (
    <Suspense fallback={<></>}>
      <LoginPageWrapper />
    </Suspense>
  );
};

export default LoginPage;
