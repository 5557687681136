import React, { Suspense } from "react";
const RegisterChallengePageWrapper = React.lazy(() =>
  import("../components/RegisterChallengePageWrapper")
);

const RegisterServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <RegisterChallengePageWrapper />
    </Suspense>
  );
};

export default RegisterServicePage;
