import OrderPage from "./pages/OrderPage";
import PaymentMethodPage from "./pages/PaymentMethodPage";
import PaymentMethodEditPage from "./pages/PaymentMethodEditPage";
import ModelProductEditPage from "./pages/ModelProductEditPage";
import ModelProductPage from "./pages/ModelProductPage";

export const orderRoute = [
  {
    path: "/orders",
    component: OrderPage,
  },
  {
    path: "/payment-method",
    component: PaymentMethodPage,
  },
  {
    path: "/payment-method/:id",
    component: PaymentMethodEditPage,
  },
  {
    path: "/payment-method/create",
    component: PaymentMethodEditPage,
  },
  {
    path: "/model-product",
    component: ModelProductPage,
  },
  {
    path: "/model-product/create",
    component: ModelProductEditPage,
  },
  {
    path: "/model-product/:id",
    component: ModelProductEditPage,
  },
];
