import React, { Suspense } from "react";
const ChildDevelopmentRoadmapWrapper = React.lazy(() =>
  import("../components/ChildDevelopmentRoadmapWrapper")
);

const DevelopmentRoadmapPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ChildDevelopmentRoadmapWrapper />
    </Suspense>
  );
};

export default DevelopmentRoadmapPage;
