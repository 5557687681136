import RegisterChallengePage from "./pages/RegisterChallengePage";

export const registerChallengeRoute = [
  {
    path: "/register-challenge-customer",
    component: RegisterChallengePage,
  },
  {
    path: "/register-challenge-expert",
    component: RegisterChallengePage,
  },
];
