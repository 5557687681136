import { Api } from '../../common/utils/request';
import { BASE_API_URL } from '../../common/constants';

const apiGetUserInfoByToken = () => {
    return Api.get(`${BASE_API_URL}/auth/user_info/`);
};
const apiGetTokenVcCloud = (data) => {
    return Api.post(`${BASE_API_URL}/oauth/token`, data);
};
const apiCreateFileUpload = (data) => {
    return Api.post(`${BASE_API_URL}/file_descriptor/`, data);
};

const checkMaximumStorageSchool = (website_id) => {
    return Api.get(`${BASE_API_URL}/website/${website_id}/check_by_plan/`);
};
const apiGetCategories = (params) => {
    return Api.get(`${BASE_API_URL}/category/`, params);
};
const apiGetTags = () => {
    return Api.get(`${BASE_API_URL}/admin/tags?type=Product`);
};
export const CommonService = {
    apiGetUserInfoByToken,
    apiGetTokenVcCloud,
    apiCreateFileUpload,
    checkMaximumStorageSchool,
    apiGetCategories,
    apiGetTags,
};
