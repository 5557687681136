import React, { Suspense } from "react";
const VoucherEditWrapper = React.lazy(() => import("../components/VoucherEditWrapper"));

const VoucherEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <VoucherEditWrapper />
    </Suspense>
  );
};

export default VoucherEditPage;