import React, { Suspense } from "react";
const DevelopmentRoadmapEditWrapper = React.lazy(() =>
  import("../components/DevelopmentRoadmapEditWrapper")
);

const DevelopmentRoadmapEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <DevelopmentRoadmapEditWrapper />
    </Suspense>
  );
};

export default DevelopmentRoadmapEditPage;
