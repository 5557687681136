import React from "react";
import { useEffect } from "react";
import toastr from "toastr";
const InputUpload = ({
  label,
  name,
  value,
  onChange,
  placeholder = "",
  type = "text",
  required = false,
  accept = "image/*",
  extensions = ["jpg", "jpeg", "png", "svg"],
  maxFileSize = 5,
  ...props
}) => {
  useEffect(() => {}, [value]);

  const onChangeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      const temp = file.name.split(".");
      if (extensions && !extensions.includes(temp.slice(-1)[0])) {
        return toastr.error("File upload không đúng định dạng");
      }
      if (file.size >= maxFileSize * 1024 * 1024) {
        return toastr.error(`File upload tối đa ${maxFileSize}MB`);
      }
      onChange(name, file);
    }
  };

  return (
    <div className="mt-2">
      <div className="mb-1 font-bold">{label}</div>
      <input
        type="file"
        className="bg-white py-2"
        placeholder={placeholder}
        name={name}
        onChange={onChangeInput}
        required={required}
        accept={accept}
        {...props}
      />
      {value ? (
        <div className="mt-2">
          {typeof value === 'object' || !value.includes(".glb") ? (
            <img
              className="h-40"
              src={value?.size ? URL.createObjectURL(value) : value}
            />
          ) : (
            value
          )}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(InputUpload);
