import LoginPage from "./pages/LoginPage";

export const authRoute = [
  {
    path: "/",
    component: LoginPage,
    noAppLayout: true,
    unauthenticated: true,
  },
  {
    path: "/login",
    component: LoginPage,
    noAppLayout: true,
    unauthenticated: true,
  },
];
