import React, { Suspense } from "react";
const NavigationWrapper = React.lazy(() => import("../components/NavigationWrapper"));

const NavigationPage = () => {
  return (
    <Suspense fallback={<></>}>
      <NavigationWrapper />
    </Suspense>
  );
};

export default NavigationPage;
