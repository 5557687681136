import { ACTION_TYPES } from "./actionTypes";
import { CacheService } from "./services";
import { COMPONENT_STATUS } from "../../common";
import toastr from "toastr";
export const setAuth = (auth) => {
  return { type: ACTION_TYPES.SET_AUTH, auth };
};
export const getWebsiteByDomain = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_WEBSITE_BY_DOMAIN,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiGetWebsiteByDomain(params)
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: ACTION_TYPES.GET_WEBSITE_BY_DOMAIN,
            payload: {
              status: COMPONENT_STATUS.SUCCESS,
              data: { ...res.data.site, domain_name: res.domain_name },
            },
          });
        } else {
          window.location = "http://dev.airclass.io/";
          // dispatch({
          //   type: ACTION_TYPES.GET_WEBSITE_BY_DOMAIN,
          //   payload: { status: COMPONENT_STATUS.ERROR },
          // });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_WEBSITE_BY_DOMAIN,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

export const getNotifications = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_NOTIFICATIONS,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiGetNotifications()
      .then((res) => {
        if (res && res.code == 200) {
          dispatch({
            type: ACTION_TYPES.GET_NOTIFICATIONS,
            payload: {
              status: COMPONENT_STATUS.SUCCESS,
              data: res.data,
              total: res.count,
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.GET_NOTIFICATIONS,
            payload: { status: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_NOTIFICATIONS,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};
export const updateCmsSetting = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_CMS_SETTING,
      payload: { name, value },
    });
  };
};

export const updateWebsiteInfo = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiUpdateWebsiteInfo(params)
      .then((res) => {
        if (res && res.id > 0) {
          toastr.success("Update success");
          dispatch({
            type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
            payload: { status: COMPONENT_STATUS.SUCCESS, data: params },
          });
        } else {
          toastr.error("Update fail");
          dispatch({
            type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
            payload: { status: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        toastr.error("Update fail");
        dispatch({
          type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};
